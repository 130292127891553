import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import moment from 'moment';
import { compressData } from '@/core/services/compressData';

export interface RegisteredTradeScrapingObject {
  id: number;
  governorate: string;
  area: string;
  propertyCategory: string;
  propertyType: string;
  action: string;
  percentage: string;
  size: number;
  block: string;
  transactionDate: string;
  salePrice: string;
  transactionNumber: number;
  areaName: string;
  propertyTypeName: string;
  notes: string;
  price: number;
  pricePerM2: number;
  areaId: number;
  propertyTypeId: number;
  duplicates: number;
}

export interface RegisteredTradeScrapingsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  isNext: boolean;
  previous: number;
  registeredTradesScraping: Array<RegisteredTradeScrapingObject>;
  errors: unknown;
}

@Module
export default class RegisteredTradeScrapingsModule
  extends VuexModule
  implements RegisteredTradeScrapingsListInfo
{
  registeredTradesScraping = [] as Array<RegisteredTradeScrapingObject>;
  totalItems = 0;
  currentPage = 1;
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get registeredTradesScraping list
   * @returns Array<RegisteredTradeScrapingObject>
   */
  get registeredTradesScrapingList(): Array<RegisteredTradeScrapingObject> {
    return this.registeredTradesScraping;
  }

  /**
   * Get total items
   * @returns number
   */
  get registeredTradesScrapingListCount(): number {
    return this.totalItems;
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADES_SCRAPING](data) {
    this.registeredTradesScraping = data.data;
    this.totalItems = data.totalItems;
    this.currentPage = data.currentPage;
    this.pageSize = data.pageSize;
    this.isNext = data.isNext;
    this.previous = data.previous;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_REGISTERED_TRADES_SCRAPING_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_REGISTERED_TRADES_SCRAPING_LIST]() {
    this.registeredTradesScraping = [] as Array<RegisteredTradeScrapingObject>;
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Action
  [Actions.CREATE_REGISTERED_TRADE_SCRAPING](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/registered-trades-scraping', data)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_SCRAPING_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  async [Actions.PULL_REGISTERED_TRADE_SCRAPING](data) {
    debugger;
    ApiService.setAuthorizationHeader();

    const startDate = moment(data.dateRange)
      .startOf('month')
      .format('DD/MM/YYYY');
    const endDate = moment(data.dateRange).endOf('month').format('DD/MM/YYYY');

    debugger;
    const response = await axios.post('http://127.0.0.1:5000/scrape', {
      fromDate: startDate,
      tillDate: endDate,
    });

    const compressedData = await compressData(response);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return ApiService.post('/registered-trades-scraping/pull-register-Trade', {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      data: compressedData,
    })
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_SCRAPING_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.DELETE_REGISTERED_TRADE_SCRAPING](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/registered-trades-scraping', id)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_SCRAPING_LIST);
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_REGISTERED_TRADE_SCRAPING]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/registered-trades', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_SCRAPING_LIST);
        this.context.commit(Mutations.RESET_REGISTERED_TRADES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_REGISTERED_TRADE_SCRAPING_CURRENT_PAGE](val) {
    this.context.commit(
      Mutations.SET_REGISTERED_TRADES_SCRAPING_CURRENT_PAGE,
      val
    );
  }

  @Action
  [Actions.GET_REGISTERED_TRADE_SCRAPING](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/registered-trades-scraping', id);
  }

  @Action
  [Actions.GET_REGISTERED_TRADES_SCRAPING](options: { limit?: number } = {}) {
    ApiService.setAuthorizationHeader();
    const params = { page: this.currentPage, limit: options.limit || 10 };
    return ApiService.query('/registered-trades-scraping', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REGISTERED_TRADES_SCRAPING, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.IMPORT_REGISTERED_TRADES](data) {
    ApiService.setAuthorizationHeader();

    return ApiService.post(
      '/registered-trades-scraping/import-register-Trade',
      data
    )
      .then((data) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
