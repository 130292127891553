
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    nextTick,
  } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import AdsFilter from '@/components/dropdown/AdsFilter.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import Multiselect from '@vueform/multiselect';
  import { ErrorMessage, Field } from 'vee-validate';

  const rejection = ref('');
  const rejectionNote = ref('');

  export default defineComponent({
    name: 'ads-listing',
    components: {
      Datatable,
      AdsFilter,
      Multiselect,
      Field,
      ErrorMessage,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const checkedAds = ref([]);
      const bulkApproveButton = ref<HTMLButtonElement | null>(null);
      const approveAllButton = ref<HTMLButtonElement | null>(null);
      const bulkRejButton = ref<HTMLButtonElement | null>(null);
      const isModalOpen = ref(false); // Track modal state
      const openModal = () => {
        isModalOpen.value = true;
      };

      const closeModal = () => {
        isModalOpen.value = false;
      };
      const modalUserData = ref();

      const isload = ref(true);
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adsListing'), [translate('ads')]);
        reinitializeComponents();
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_ADS_STORE);
      });

      // debugger;
      const fillter = computed(() => store.getters.getadsFiltter);

      if (fillter.value) {
        await store.dispatch(Actions.UPDATE_AD_FILTER, fillter.value);
        // console.log(fillter.value);
        await store.dispatch(Actions.GET_ADS);
      }

      const userId = route.query.userId || '';
      if (Number(userId)) {
        await store.dispatch(Actions.UPDATE_AD_FILTER, { memberId: userId });
      }
      await store.dispatch(Actions.GET_ADS);
      store.dispatch(Actions.GET_ADS_APPRS_INFO);
      const loading = ref(false);
      const tableHeader = ref([
        {
          key: 'checkbox',
          sortable: false,
        },
        {
          name: translate('id'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('thumbnail'),
          key: 'thumbnail',
          sortable: false,
        },
        {
          name: translate('publishName'),
          key: 'publishName',
          sortable: true,
        },
        // {
        //   name: translate('publishMobile'),
        //   key: 'publishMobile',
        //   sortable: true,
        // },
        {
          name: translate('areaName'),
          key: 'areaName',
          sortable: true,
        },
        {
          name: translate('propertyCategory'),
          key: 'propertyCategory',
          sortable: true,
        },
        {
          name: translate('propertyType'),
          key: 'propertyType',
          sortable: true,
        },
        {
          name: translate('size'),
          key: 'size',
          sortable: true,
        },
        {
          name: translate('price'),
          key: 'price',
          sortable: true,
        },
        {
          name: translate('adsType'),
          key: 'adsType',
          sortable: true,
        },
        {
          name: translate('createdAt'),
          key: 'createdAt',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'statusId',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const viewAdCus = async (ad) => {
        if (ad.representativeId) {
          const id = ad.representativeId;
          const url = router.resolve({
            name: 'ads-viewing',
            params: { id },
          }).href;
          window.open(url, '_blank');
        }
      };

      const viewAdCusList = async (id) => {
        const url = router.resolve({
          name: 'ads-listing',
          query: { userId: id },
        }).href;
        window.open(url, '_blank');
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AD_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_ADS);
        store.dispatch(Actions.GET_ADS_APPRS_INFO);
        loading.value = false;
      };

      const viewAd = async (id) => {
        router.push({ name: 'ads-viewing', params: { id } });
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
      };

      const editAd = async (id) => {
        router.push({ name: 'ads-editing', params: { id } });
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
      };
      const updateAd = async (id) => {
        router.push({ name: 'ads-updating', params: { id } });
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
      };

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AD_FILTER, value);
        await store.dispatch(Actions.UPDATE_AD_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_ADS);
        store.dispatch(Actions.GET_ADS_APPRS_INFO);
        loading.value = false;
      };

      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AD_FILTER, values.value);
        await store.dispatch(Actions.UPDATE_AD_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_ADS);
        store.dispatch(Actions.GET_ADS_APPRS_INFO);
        loading.value = false;
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }
      };

      const approveAds = async () => {
        if (bulkApproveButton.value) {
          // eslint-disable-next-line
          bulkApproveButton.value!.disabled = true;
          bulkApproveButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          ids: checkedAds.value,
        };
        await store.dispatch(Actions.BULK_APPROVE_ADS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          bulkApproveButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          bulkApproveButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_REQUEST'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            loading.value = true;
            await store.dispatch(Actions.GET_ADS);
            store.dispatch(Actions.GET_ADS_APPRS_INFO);
            loading.value = false;
            //Deactivate indicator
            bulkApproveButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            bulkApproveButton.value!.disabled = false;
            checkedAds.value.length = 0;
          });
        }
      };

      const rejectAds = async () => {
        if (bulkRejButton.value) {
          // eslint-disable-next-line
          bulkRejButton.value!.disabled = true;
          bulkRejButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          ids: checkedAds.value,
          rejectReason: rejectionNote.value,
        };
        await store.dispatch(Actions.BULK_REJECT_ADS, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          bulkRejButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          bulkRejButton.value!.disabled = false;
        } else {
          checkedAds.value = [];
          checkedAds.value.length = 0;
          closeModal();
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_REQUEST'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            loading.value = true;
            await store.dispatch(Actions.GET_ADS);
            store.dispatch(Actions.GET_ADS_APPRS_INFO);
            loading.value = false;
            closeModal();
            //Deactivate indicator
            bulkRejButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            bulkRejButton.value!.disabled = false;
            checkedAds.value.length = 0;
            rejectionNote.value = '';
            rejection.value = '';
          });
        }
      };
      const approveAll = async () => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate('confirmApproveAll'),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          if (approveAllButton.value) {
            // eslint-disable-next-line
            approveAllButton.value!.disabled = true;
            approveAllButton.value.setAttribute('data-kt-indicator', 'on');
          }

          await store.dispatch(Actions.APPROVE_ALL_ADS);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
            //Deactivate indicator
            approveAllButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            approveAllButton.value!.disabled = false;
          } else {
            Swal.fire({
              text: translate('SUCCESSFULLY_UPDATED_ADS_REQUEST'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(async () => {
              await store.dispatch(Actions.GET_ADS);
              store.dispatch(Actions.GET_ADS_APPRS_INFO);
              //Deactivate indicator
              approveAllButton.value?.removeAttribute('data-kt-indicator');
              // eslint-disable-next-line
            });
          }
        }
      };
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      const showModal = async (ad) => {
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        isload.value = true;
        const { data } = await store.dispatch(
          Actions.GET_AD_USER_INFO,
          ad.memberId
        );

        modalUserData.value = data.data;
        isload.value = false;
        await store.dispatch(
          Actions.REMOVE_BODY_CLASSNAME,
          'page-loading-over'
        );
      };

      const rejectReasonsData = await store.dispatch(
        Actions.GET_REJECT_ADS_REASON
      );

      const rejectReasons = rejectReasonsData.data.data;

      const updaterejectionNote = async () => {
        debugger;
        await nextTick();
        if (rejection.value) rejectionNote.value = rejection.value;
      };

      return {
        isload,
        modalUserData,
        showModal,
        approveAll,
        approveAllButton,
        bulkApproveButton,
        bulkRejButton,
        approveAds,
        checkedAds,
        onTypeChange,
        onReset,
        tableData: computed(() => store.getters.adsList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.adsListCount),
        pageChanged,
        goTo,
        viewAd,
        editAd,
        updateAd,
        loading,
        can,
        viewAdCus,
        viewAdCusList,
        closeModal,
        isModalOpen,
        openModal,
        rejection,
        rejectionNote,
        updaterejectionNote,
        rejectReasons,
        rejectAds,
      };
    },
  });
