
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { RegisteredTradeObject } from '@/store/modules/RegisteredTrades/RegisteredTradesModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import moment from 'moment';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'registered-trades-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('registeredTradesUpdating'), [
          translate('appraisals'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
        type: 'registeredTrades',
      });
      const { data } = await store.dispatch(
        Actions.GET_REGISTERED_TRADE,
        route.params.id
      );
      data.data.transactionDate = moment(data.data.transactionDate).format(
        'YYYY-MM-DD'
      );

      const registeredTrade = ref<RegisteredTradeObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const registeredTradeSchema = Yup.object().shape({
        // transactionNumber: Yup.number()
        //   .required(() => translate('TRANSACTION_NUMBER_IS_REQUIRED_FIELD'))
        //   .label('transactionNumber'),
        size: Yup.number()
          .required(() => translate('SIZE_IS_REQUIRED_FIELD'))
          .label('size'),
        // price: Yup.number()
        //   .required(() => translate('PRICE_IS_REQUIRED_FIELD'))
        //   .label('price'),
        pricePerM2: Yup.number()
          .required(() => translate('PRICE_PER_M2_IS_REQUIRED_FIELD'))
          .label('pricePerM2'),
      });

      const updateDesc = async (values) => {
        debugger;
        const selectedDesc = registeredDescs.value.find(
          (desc) => desc.id == values
        );
        registeredTrade.value.descriptionAr = selectedDesc?.titleAr;
        registeredTrade.value.descriptionEn = selectedDesc?.titleEn;
      };

      const updateNotes = async (values) => {
        const selectedNotes = registeredNotes.value.find(
          (desc) => desc.id == values
        );
        registeredTrade.value.notesAr = selectedNotes?.titleAr;
        registeredTrade.value.notesEn = selectedNotes?.titleEn;
      };

      const onSubmitUpdate = async (values) => {
        if (
          registeredTrade.value.areaId == 0 ||
          registeredTrade.value.propertyTypeId == 0
        )
          return;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.transactionDate = registeredTrade.value?.transactionDate;
        if (values.price) {
          const numericValue = values.price.replace(/[^0-9.]/g, '');
          values.price = Number(numericValue);
        }

        values.areaId = Number(registeredTrade.value?.areaId);
        values.propertyTypeId = Number(registeredTrade.value?.propertyTypeId);
        delete values.description;
        delete values.notes;
        await store.dispatch(Actions.UPDATE_REGISTERED_TRADE, {
          id: registeredTrade.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_REGISTERED_TRADE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'registered-trades-listing' });
          });
        }
      };

      const updatePricePerSqrM = async () => {
        if (
          registeredTrade.value &&
          registeredTrade.value.size &&
          registeredTrade.value.price
        ) {
          registeredTrade.value.pricePerM2 = Number(
            (
              Number(registeredTrade.value.price) /
              Number(registeredTrade.value.size)
            ).toFixed(2)
          );
        }
      };

      await store.dispatch(Actions.GET_REGISTERED_TRADES_ASSETS, 6);
      await store.dispatch(Actions.GET_REGISTERED_TRADES_ASSETS, 7);
      const registeredNotes = computed(() => store.getters.getRegisteredNotes);
      const registeredDescs = computed(() => store.getters.getRegisteredDesc);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      const formattedPrice = computed({
        get() {
          // Format the raw price as a string with commas (e.g., 10,000)
          return registeredTrade.value.price.toLocaleString();
        },
        set(value: string) {
          debugger;
          // Remove non-numeric characters (e.g., commas) and update the raw price
          var numericValue = value.replace(/[^0-9.]/g, '');
          // // Optionally: ensure only one decimal point
          // numericValue1 = numericValue1.replace(/(\..*)\./g, '$1');
          registeredTrade.value.price = Number(numericValue);
        },
      });

      return {
        formattedPrice,
        updatePricePerSqrM,
        areas: computed(() => store.getters.allListAreas),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        onSubmitUpdate,
        submitButton,
        translate,
        registeredTradeSchema,
        goBack,
        registeredTrade,
        can,
        updateNotes,
        updateDesc,
        registeredDescs,
        registeredNotes,
      };
    },
  });
