
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'registered-trades-scraping-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(
          translate('RegisteredTradesScrapingListing'),
          [translate('RegisteredTradesScraping')]
        );
      });

      await store.dispatch(Actions.GET_REGISTERED_TRADES_SCRAPING);
      const loading = ref(false);
      const tableHeader = ref([
        // {
        //   name: translate('idColumn'),
        //   key: 'id',
        //   sortable: true,
        // },
        {
          name: translate('governorate'),
          key: 'governorate',
          sortable: true,
        },
        {
          name: translate('area'),
          key: 'area',
          sortable: true,
        },
        {
          name: translate('propertyCategory'),
          key: 'propertyCategory',
          sortable: true,
        },
        {
          name: translate('propertyType'),
          key: 'propertyType',
          sortable: true,
        },
        // {
        //   name: translate('action'),
        //   key: 'action',
        //   sortable: true,
        // },
        // {
        //   name: translate('percentage'),
        //   key: 'percentage',
        //   sortable: true,
        // },
        {
          name: translate('block'),
          key: 'block',
          sortable: true,
        },
        {
          name: translate('transactionDate'),
          key: 'transactionDate',
          sortable: true,
        },
        {
          name: translate('size'),
          key: 'size',
          sortable: true,
        },
        {
          name: translate('salePrice'),
          key: 'salePrice',
          sortable: true,
        },
        {
          name: translate('duplicates'),
          key: 'duplicates',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusRegisteredTrades = async (registeredTradeScraping) => {
        const deletedMessage = registeredTradeScraping.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(
            Actions.DELETE_REGISTERED_TRADE_SCRAPING,
            registeredTradeScraping.id
          );
          await store.dispatch(Actions.GET_REGISTERED_TRADES_SCRAPING);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const SuccessMsg = registeredTradeScraping.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(SuccessMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editRegisteredScrapingTrades = (id) => {
        router.push({
          name: 'registered-trades-scraping-editing',
          params: { id },
        });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_REGISTERED_TRADE_SCRAPING_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_REGISTERED_TRADES_SCRAPING);
        loading.value = false;
      };

      const fireError = () => {
        Swal.fire({
          text: '',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: translate('tryAgainExcl'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };

      const uploadRegisteredTrades = async (e) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const registeredTradesFile = e.target.files[0];
        let formData = new FormData();
        formData.append('excel', registeredTradesFile);
        const isUpload = await store.dispatch(
          Actions.IMPORT_REGISTERED_TRADES,
          formData
        );
        if (isUpload && isUpload.data) {
          Swal.fire({
            html:
              translate('SUCCESSFULLY_UPLOAD_REGISTERED_TRADE') +
              `<br>new : ${isUpload.data ? isUpload.data.data : ''}`,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            await store.dispatch(Actions.GET_REGISTERED_TRADES_SCRAPING);
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            e.target.value = '';
          });
        } else {
          fireError();
          e.target.value = '';
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.registeredTradesScrapingList),
        tableHeader,
        toggleStatusRegisteredTrades,
        translate,
        totalItems: computed(
          () => store.getters.registeredTradesScrapingListCount
        ),
        pageChanged,
        uploadRegisteredTrades,
        goTo,
        editRegisteredScrapingTrades,
        loading,
        can,
        submitButton,
      };
    },
  });
