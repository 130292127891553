
  import { defineComponent, onMounted, ref, reactive, computed } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import moment from 'moment';
  import Multiselect from '@vueform/multiselect';
  export default defineComponent({
    name: 'registered-trades-totals-reports',
    components: {
      Multiselect,
    },
    async setup() {
      const showExportBtn = ref(false);
      const data = ref();
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItems = ref<any>({
        dateFrom: '',
        dateTo: '',
        areasIds: [],
        govIds: [],
      });
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      const goBack = () => {
        router.back();
      };
      // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS);
      // data.value = store.getters.registeredTradesDashboard;
      const onSubmitCreate = async () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS, values);
        // data.value = store.getters.registeredTradesReports;
        await store.dispatch(
          Actions.GET_REGISTERED_TRADE_TOTALS_REPORTS,
          selectedItems
        );
        data.value = store.getters.registeredTradesReportsTotals;
        if (data.value.data.length > 0) showExportBtn.value = true;
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_GET_REPORTS_DATA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            // router.push({ name: 'ad-types-listing' });
          });
        }
      };
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('RegisteredTradesTotalsReports'), [
          translate('Reports'),
        ]);
      });
      const excelHeader = reactive({
        area: 'govTitle',
        NumberOfTrades: 'count',
        TotalPrice: 'sum',
      });
      reinitializeComponents();
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_ALL_GOV_LIST);
      await store.dispatch(Actions.GET_REGISTERED_TRADES_DESC);
      return {
        govs: computed(() => store.getters.allListGov),
        areas: computed(() => store.getters.allListAreas),
        registeredTradesDesc: computed(
          () => store.getters.getregisteredTradesDesc
        ),
        excelHeader,
        dateNow: new Date().toISOString().slice(0, 10),
        showExportBtn,
        data,
        selectedItems,
        onSubmitCreate,
        submitButton,
        translate,
        goBack,
        can,
      };
    },
  });
