
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'properties-creating',
    props: {
      auctionId: { type: Number, required: true },
    },
    emit: ['list-changed'],
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newPropertyModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const statusId = ref<number | null>(2);
      const selectedArea = ref<number>(0);
      const selectedPropertyType = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const property = ref<any | null>({
        id: 0,
        size: '',
        startingPrice: '',
        startingPricePerSqrM: '',
        salePrice: '',
        salePricePerSqrM: '',
        percentagePremiumPaid: '',
      });
      const showFileInput = ref(true);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const validationSchema = Yup.object().shape({
        // propertyTypeId: Yup.number()
        //   .required(() => translate('PROPERTY_TYPE_IS_REQUIRED_FIELD'))
        //   .label('Property Type'),
        statusId: Yup.number()
          .required(() => translate('AUCTION_STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        // areaId: Yup.number()
        //   .required(() => translate('AREA_IS_REQUIRED_FIELD'))
        //   .label('Status'),
        block: Yup.string().label('Block'),
        size: Yup.string()
          .required(() => translate('SIZE_IS_REQUIRED_FIELD'))
          .label('Size'),
        salePrice: Yup.string().label('Sale Price'),
        startingPrice: Yup.string()
          .required(() => translate('STARTING_PRICE_IS_REQUIRED_FIELD'))
          .label('Starting Price'),
        startingPricePerSqrM: Yup.string().label(
          'Starting Price Per Square Meter'
        ),
        salePricePerSqrM: Yup.string().label('Sale Price Per Square Meter'),
        additionalInfoEn: Yup.string().label('Additional Information English'),
        additionalInfoAr: Yup.string().label('Additional Information Arabic'),
        percentagePremiumPaid: Yup.string().label('Percentage Premium Paid'),
        plot: Yup.string().label('Plot'),
        attachment: Yup.mixed().label('Attachment'),
      });

      const updateStartingPricePerSqrM = async () => {
        if (
          property.value &&
          property.value.size &&
          property.value.startingPrice
        ) {
          const numericStartingPrice = property.value.startingPrice.replace(
            /[^0-9.]/g,
            ''
          );

          property.value.startingPricePerSqrM = (
            numericStartingPrice / Number(property.value.size)
          ).toFixed(2);
        }
      };

      const updateSalePricePerSqm = async () => {
        if (property.value && property.value.size && property.value.salePrice) {
          const numericSalePrice = property.value.salePrice.replace(
            /[^0-9.]/g,
            ''
          );
          property.value.salePricePerSqrM = (
            numericSalePrice / Number(property.value.size)
          ).toFixed(2);
        }
      };

      const updatePercentagePremiumPaid = async () => {
        if (
          property.value &&
          property.value.salePrice &&
          property.value.startingPrice
        ) {
          const numericStartingPrice = property.value.startingPrice.replace(
            /[^0-9.]/g,
            ''
          );
          const numericSalePricePrice = property.value.salePrice.replace(
            /[^0-9.]/g,
            ''
          );

          const difference = numericSalePricePrice - numericStartingPrice;
          property.value.percentagePremiumPaid = (
            (difference / numericStartingPrice) *
            100
          ).toFixed(2);
        }
      };

      const submit = async (values, { resetForm }) => {
        if (selectedArea.value == 0 || selectedPropertyType.value == 0) return;

        if (!submitButtonRef.value) {
          return;
        }

        //Disable button
        submitButtonRef.value.disabled = true;
        // Activate indicator
        submitButtonRef.value.setAttribute('data-kt-indicator', 'on');

        let attachmentsFormData: FormData | null = null;

        if (values.attachment) {
          attachmentsFormData = new FormData();
          attachmentsFormData.append('attachment', values.attachment);
        }
        if (values.statusId != 1) {
          delete values.salePrice;
          delete values.salePricePerSqrM;
        }
        if (values.startingPrice) {
          const numericStartingPrice = values.startingPrice.replace(
            /[^0-9.]/g,
            ''
          );
          values.startingPrice = Number(numericStartingPrice).toString();
        }
        if (values.salePrice) {
          const numericSalePrice = values.salePrice.replace(/[^0-9.]/g, '');
          values.salePrice = Number(numericSalePrice).toString();
        }
        values.areaId = selectedArea.value;
        values.propertyTypeId = selectedPropertyType.value;
        delete values.attachment;

        const data = await store.dispatch(Actions.CREATE_AUCTION_PROPERTY, {
          data: values,
          auctionId: props.auctionId,
        });

        if (data?.id && attachmentsFormData) {
          await store.dispatch(Actions.UPLOAD_AUCTION_ATTACHMENTS, {
            data: attachmentsFormData,
            auctionId: data.id,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_PROPERTY'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            emit('list-changed');
            await resetForm();

            showFileInput.value = false; // Hide the file input
            setTimeout(() => {
              showFileInput.value = true; // Show it again to reset
            }, 0); // Short delay to force DOM re-render

            const fileInput = document.getElementsByName('attachment')[0];
            fileInput['value'] = '';
            statusId.value = 2;
            selectedPropertyType.value = 0;
            selectedArea.value = 0;
            // document.getElementsByName('attachment')[0].value = '';

            store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
            hideModal(newPropertyModalRef.value);
            await store.dispatch(
              Actions.GET_AUCTION_PROPERTIES,
              props.auctionId
            );

            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
          });
        }
      };

      await Promise.all([
        store.dispatch(Actions.GET_ALL_AUCTION_PROPERTY_TYPES),
        store.dispatch(Actions.GET_ALL_AUCTION_STATUSES),
        store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'auction' }),
      ]);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      // Add the event listener for 'keydown' to check for the 'Enter' key
      window.addEventListener('keydown', function () {
        // if (e.key === 'Enter') {
        Swal.close(); // Close the popup when 'Enter' is pressed
        // }
      });

      const onStartingPriceInput = async () => {
        const input = property.value.startingPrice.replace(/,/g, ''); // Remove commas for parsing
        const numericValue = parseFloat(input);

        if (!isNaN(numericValue)) {
          property.value.startingPrice = new Intl.NumberFormat('en-US').format(
            numericValue
          ); // Format for display
        }
      };

      const onSalePriceInput = async () => {
        const input = property.value.salePrice.replace(/,/g, ''); // Remove commas for parsing
        const numericValue = parseFloat(input);

        if (!isNaN(numericValue)) {
          property.value.salePrice = new Intl.NumberFormat('en-US').format(
            numericValue
          );
        }
      };

      return {
        onStartingPriceInput,
        onSalePriceInput,
        showFileInput,
        statusId,
        property,
        updatePercentagePremiumPaid,
        updateSalePricePerSqm,
        updateStartingPricePerSqrM,
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        newPropertyModalRef,
        propertyTypes: computed(() => store.getters.allAuctionPropertyTypes),
        statuses: computed(() => store.getters.auctionStatusesList),
        areas: computed(() => store.getters.allListAreas),
        selectedArea,
        selectedPropertyType,
      };
    },
  });
