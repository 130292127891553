import { User } from '@/store/modules/AuthModule';

const USER_KEY = 'auth_user' as string;

/**
 * @description get current user form localStorage
 */
export const getUser = (): User | null => {
  return JSON.parse(<string>window.localStorage.getItem(USER_KEY));
};

/**
 * @description save current user into localStorage
 * @param user User
 */
export const saveUser = (user: User): void => {
  window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  window.localStorage.setItem('loginType', user['userType'] || '');
};

/**
 * @description remove user form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.removeItem(USER_KEY);
};

export default { getUser, saveUser, destroyUser };
